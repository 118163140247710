<template>
  <div>
    <el-form ref="form" :model="queryForm" label-width="120px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24">
          <el-col :span="6">
            <el-form-item label="调拨单号">
              <el-input
                v-model="queryForm.transferOrderCode"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="调拨单状态">
              <Select
                v-model="queryForm.stateDictList"
                :select-options="_getAllCommodityDict('SCM_TRANSFER_STATE')"
                :configuration="optionsConfig"
                clearable
                multiple
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Style">
              <el-select
                v-model="queryForm.style"
                value-key="id"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
                style="width: 100%;"
                @change="styleSelectChange"
              >
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Color">
              <el-select v-model="queryForm.color" clearable filterable>
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :label="item.colorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="6">
            <el-form-item label="Size">
              <el-select v-model="queryForm.size" clearable filterable>
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :label="item.size"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="调出备货渠道">
              <Select
                v-model="queryForm.outStockChannelIdList"
                :select-options="StockChannelOptions"
                :configuration="{ key: 'channelId', label: 'channelName', value: 'channelId' }"
                clearable
                multiple
                filterable
                collapse-tags
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="调入备货渠道">
              <Select
                v-model="queryForm.inStockChannelIdList"
                :select-options="StockChannelOptions"
                :configuration="{ key: 'channelId', label: 'channelName', value: 'channelId' }"
                clearable
                multiple
                filterable
                collapse-tags
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="季节">
              <Select
                v-model="queryForm.seasonIdList"
                :select-options="_getAllCommodityDict('SEASON')"
                :configuration="optionsConfig"
                clearable
                multiple
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="6">
            <el-form-item label="定位">
              <Select
                v-model="queryForm.positionIdList"
                style="width:100%;"
                api-key="position"
                clearable
                filterable
                collapse-tags
                multiple
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="调配仓库">
              <Select
                v-model="queryForm.provisionWarehouseCodeList"
                :select-options="warehouseOption"
                :configuration="optionsWarehouseConfig"
                clearable
                multiple
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="销售预测版本">
              <Select
                v-model="queryForm.currencyCodeList"
                :select-options="dsbVertionOptions"
                :configuration="{ key: 'version', label: 'versionCode', value: 'version' }"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="操作人">
              <el-select v-model="queryForm.opreatId" clearable filterable>
                <el-option v-for="item in useroptions" :key="item.id" :label="item.username" :value="item.id" style="width:100%;" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="操作时间" :class="$i18n.locale">
              <el-date-picker
                v-model="queryForm.value1"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width:100%;"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:20%;">
        <el-col class="row-center">
          <el-button type="primary" :loading="Loading" @click="handleQuery()">{{ $t('page.search') }}</el-button>
          <el-button @click="handleQueryFormReset()">{{ $t('page.reset') }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-row :gutter="10" class=" mb-3">
      <ImportButton v-permission="'exportA'" export-key="scm_dsb_provision_log_export" :params="queryParams" btn-type="primary" />
    </el-row>
    <el-table
      v-loading="Loading"
      :data="TableData"
      border
      style="width: 100%; margin-top: 20px"
      :header-cell-style="{background:'#fafafa'}"
      show-overflow-tooltip
    >
      <el-table-column
        label="序号"
        align="center"
        width="55"
        type="index"
        :resizable="false"
      />
      <el-table-column v-for="(item,index) in logColumns" :key="index" :prop="item.prop" :label="item.label" :formatter="item.formatter" :width="item.width" show-overflow-tooltip align="center" />
    </el-table>
    <Paging :pager="pager" end @pagination="pagerUpdate" />

  </div>
</template>

<script>
import Select from '@/components/Selection'
import Paging from '@/components/Pagination'
import { omit } from 'lodash'
import { queryBrandList } from '@/api/listSelection'
import { getPhysicsWarehouseList, queryStyleList, queryColorList, querySizeList, dsbVersionList, usersList, getplatformSiteShop, provisionLog, StockChannelApi } from '@/api/scm-api'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { logColumns } from '../columns'
import ImportButton from '@/components/ExportFile'

import { handleMonitorParams } from '@/utils'
import {
  trackSiteSearch,
  handleBreadTitle,
  trackExportEvent
} from '@/utils/monitor'
export default {
  components: {
    Select,
    Paging,
    ImportButton

  },
  mixins: [commodityInfoDict],
  data() {
    return {
      type: '',
      optionsWarehouseConfig: { key: 'id', label: 'warehouseName', value: 'logicWarehouseCode' },
      optionsConfig: { key: 'val', label: 'label', value: 'val' },
      tableLoading: false,
      Loading: false,
      TableData: [],
      multipleSelectionTable: [],
      queryForm: {
        style: '',
        color: '',
        size: '',
        transferOrderCode: '',
        stateDictList: [],
        currencyCodeList: '',
        InplatformSiteShop: '',
        OutplatformSiteShop: ''
      },
      validDialog: false,
      pager: {
        current: 1,
        size: 10,
        pages: 1,
        total: 0
      },
      // validForm: { stateReason: '' },
      styleOptions: [],
      colorOptions: [],
      // colorList: [],
      sizeOptions: [],
      dsbVertionOptions: [],
      StockChannelOptions: [],
      useroptions: [],
      warehouseOption: [],
      platOptions: [],
      categoryOptions: [],
      brandOptions: [],
      alldatas: [],
      logColumns
    }
  },
  computed: {
    queryParams() {
      const { value1, currencyCodeList, style, size, color, opreatId } = this.queryForm
      const [operateTimeStart, operateTimeEnd] = value1 || []
      const colorNameList = color ? [this.colorOptions.find(item => item.id === color).colorName] : []
      const sizeNameList = size ? [this.sizeOptions.find(item => item.id === size).size] : []
      const styleIdList = style ? [style] : []
      const operateIdList = opreatId ? [opreatId] : []
      const fcstVersionIdList = currencyCodeList ? [currencyCodeList] : []
      // const transferOrderCodeList = stateTransferOrderCode ? [stateTransferOrderCode] : []
      return Object.assign({},
        omit(this.queryForm, ['value1']),
        this.pager, { operateTimeStart, operateTimeEnd, colorNameList, sizeNameList, styleIdList },
        { fcstVersionIdList, operateIdList }
        // { inStockChannelIdList: InplatformSiteShop, outStockChannelIdList: OutplatformSiteShop } InplatformSiteShop, OutplatformSiteShop,
      )
    }
  },

  async mounted() {
    this._queryWarehouseList()
    this._queryBrandList()
    this._queryStyleList()
    // this._queryColorList()
    // this._querySizeList()
    this._dsbVersionList()
    this._StockChannelList()
    this._usersList()
    this._listPlatformSiteShop()
    this.handleQuery()
  },
  methods: {

    async _queryBrandList() {
      const { datas } = await queryBrandList()
      this.brandOptions = datas
    },
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    styleSelectChange(value) {
      this.queryForm.color = ''
      this.queryForm.size = ''
      const data = this.styleOptions.find(item => item.id === value) || []
      this._queryColorList(data.id)
      this._querySizeList(data.id)
    },
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    async _dsbVersionList() {
      const { datas } = await dsbVersionList()
      this.dsbVertionOptions = datas
      // this.queryForm.currencyCodeList = datas[datas.length - 1].versionCode
    },
    async _StockChannelList() {
      const { datas } = await StockChannelApi()
      this.StockChannelOptions = datas
    },
    async _usersList() {
      const { datas } = await usersList()
      this.useroptions = datas
    },
    async _listPlatformSiteShop() {
      const { datas } = await getplatformSiteShop()
      // this.getDataTree(datas)
      this.alldatas = datas
    },
    async _queryWarehouseList() {
      const { datas } = await getPhysicsWarehouseList()
      this.warehouseOption = datas
    },

    pagerUpdate(val) {
      this.pager = val
      this._queryList()
    },
    handleQuery() {
      this.pager.current = 1
      trackSiteSearch(
        JSON.stringify(handleMonitorParams(this.queryParams)),
        handleBreadTitle(this.$route),
        this.pager.size
      )
      this._queryList()
    },
    async _queryList() {
      try {
        this.tableLoading = true
        this.Loading = true
        // const { code, datas: { pager: { total }}} = await provisionLog(this.queryParams)
        const { code, datas: { records, pager }} = await provisionLog(this.queryParams, this.pager)
        if (code === 0) {
          this.pager = pager
          this.TableData = records
        }
        this.tableLoading = false
        this.Loading = false
      } finally {
        this.tableLoading = false
        this.Loading = false
      }
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.handleQuery()
      // this.queryForm.currencyCodeList = this.dsbVertionOptions[this.dsbVertionOptions.length - 1].versionCode
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
